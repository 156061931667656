import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import SeoAuditForm from "../components/seo-audit-form";
import parse from "html-react-parser";

const SeoAuditPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpSeoPage(title: { eq: "SEO Audit" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}

			jasonImg: wpMediaItem(title: { eq: "jasoncornes-image-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "seoaudit" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoTopImg: wpMediaItem(title: { eq: "Demystifying SEO presentation" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoAuditPage {
				seoAuditPageFields {
					auditWhatYouNeedSection {
						description
						title
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					bookYourFreeAuditSection {
						description
						title
					}
					freeSeoAuditSection {
						description
						title
						image1 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						image2 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					heroSection {
						title
						description
						button1 {
							link
							text
						}
					}
					whatsIncludedSection {
						title
						item1 {
							description
							title
						}
						item2 {
							description
							title
						}
						item3 {
							description
							title
						}
						item4 {
							description
							title
						}
						item5 {
							description
							title
						}
						item6 {
							description
							title
						}
						item7 {
							description
							title
						}
						item8 {
							description
							title
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;
	const {
		wpSeoAuditPage: {
			seoAuditPageFields: {
				auditWhatYouNeedSection,
				bookYourFreeAuditSection,
				freeSeoAuditSection,
				heroSection,
				whatsIncludedSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "SEO Audit",
				item: {
					url: `${siteUrl}/seo-audit`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/seo-audit`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-it-covers"
								>
									What it covers
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#book-now"
								>
									Book now
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={5} className=" ">
								<div>{parse(auditWhatYouNeedSection.title)}</div>
								<div>{parse(auditWhatYouNeedSection.description)}</div>
							</Col>
							<Col lg={7}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={auditWhatYouNeedSection.image.gatsbyImage}
										alt={auditWhatYouNeedSection.image.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(58deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-9 "
				>
					<Container>
						<Row className=" align-items-center">
							<Col lg={6} className="position-relative d-none d-lg-block">
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 30px #00000029",
										height: "45rem",
										overflow: "hidden",
									}}
									className="bg-white me-auto   w-60"
								>
									<GatsbyImage
										className="w-100 "
										image={freeSeoAuditSection.image1.gatsbyImage}
										alt={freeSeoAuditSection.image1.altText}
										objectPosition="top center"
									/>
								</div>

								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 30px #00000029",
										overflow: "hidden",
									}}
									className="bg-white position-absolute end-20 bottom-10   w-60"
								>
									<GatsbyImage
										className="w-100 "
										image={freeSeoAuditSection.image2.gatsbyImage}
										alt={freeSeoAuditSection.image2.altText}
										objectPosition="top center"
									/>
								</div>
							</Col>
							<Col lg={6}>
								<div>{parse(freeSeoAuditSection.title)}</div>
								<div>{parse(freeSeoAuditSection.description)}</div>
								<SeoAuditForm extraButton />
							</Col>
						</Row>
					</Container>
				</section>

				<section id="what-it-covers" className="py-5 py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col>
								<div>{parse(whatsIncludedSection.title)}</div>
							</Col>
						</Row>
						<Row className="g-lg-6 g-5">
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-1.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item1.title}
								</h3>
								<div>{parse(whatsIncludedSection.item1.description)}</div>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-2.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item2.title}
								</h3>
								<div>{parse(whatsIncludedSection.item2.description)}</div>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-3.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item3.title}
								</h3>
								<div>{parse(whatsIncludedSection.item3.description)}</div>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-4.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item4.title}
								</h3>
								<div>{parse(whatsIncludedSection.item4.description)}</div>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-5.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item5.title}
								</h3>
								<div>{parse(whatsIncludedSection.item5.description)}</div>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-6.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item6.title}
								</h3>
								<div>{parse(whatsIncludedSection.item6.description)}</div>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-7.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item7.title}
								</h3>
								<div>{parse(whatsIncludedSection.item7.description)}</div>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../images/SEO-audit-Icon-8.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									{whatsIncludedSection.item8.title}
								</h3>
								<div>{parse(whatsIncludedSection.item8.description)}</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="book-now" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<div>{parse(bookYourFreeAuditSection.title)}</div>
								<div>{parse(bookYourFreeAuditSection.description)}</div>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<div id="faq">
					<Faq page="SEO Audit" />
				</div>
				<OSSection
					link3="/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/>
			</Layout>
		</>
	);
};

export default SeoAuditPage;
